import { format, isEqual, isSameDay } from 'date-fns'

export default function eventDatetimeBuilder(start: string, end: string) {
  if (isEqual(start, end)) {
    return start ? format(start, 'DD MMM HH:mm') : ''
  }
  if (isSameDay(start, end)) {
    return [format(start, 'DD MMM HH:mm'), format(end, 'HH:mm')].join(' - ')
  }
  return [format(start, 'DD MMM HH:mm'), format(end, 'DD MMM HH:mm')].join(' - ')
}
