import './class-component-hooks.ts'
import 'c3/c3.min.css'

import * as Integrations from '@sentry/integrations'
import * as Sentry from '@sentry/browser'
import { LogLevel } from '@sentry/types'
import Vue from 'vue'

// The sequence will affect the fetch-intercept
// eslint-disable-next-line import/order
import { StoreHelper, Vuetify } from './plugins'

import { AppConfigEntity } from '~/apollo/types'
import apolloClient from '~/apollo'
import getAppConfig from '~/apollo/queries/getAppConfig.gql'

import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
Vue.use(StoreHelper)

// Get Config
async function init() {
  try {
    try {
      const { data }: any = await apolloClient.query({
        query: getAppConfig,
      })
      const dashboardAppConfig: AppConfigEntity = data.getAppConfig

      store.dispatch('setValidGroupIds', dashboardAppConfig.dtlIncludeGroups ?? [])

      if (dashboardAppConfig.ga && dashboardAppConfig.ga.enable) {
        const evt = new CustomEvent('gaready', { detail: dashboardAppConfig.ga })
        window.dispatchEvent(evt)
      }
      if (
        dashboardAppConfig.sentry &&
        dashboardAppConfig.sentry.enable &&
        dashboardAppConfig.sentry.dsn
      ) {
        // init sentry
        Sentry.init({
          attachStacktrace: true,
          dsn: dashboardAppConfig.sentry.dsn,
          environment: 'Production',
          logLevel: LogLevel.Error,
          release: process.env.VUE_APP_DEPLOYMENT,
          integrations: [
            new Integrations.Vue({
              Vue,
              attachProps: true,
            }),
          ],
        })
        // set tags
        Sentry.configureScope(scope => {
          scope.setTag('tier', 'Frontend')
          scope.setLevel(Sentry.Severity.Error)
        })
      }
    } catch (e) {
      console.log(e)
    }
    /* eslint-disable no-new */
    new Vue({
      el: '#app',
      router,
      store,
      render: h => h(App),
      vuetify: Vuetify,
    })
  } catch (e) {
    throw e
  }
}

init()
