import { WorkflowStatus } from '@technology/collaborate-next-js'

const basicConfig = {
  accountApiRootUrl: '/api/accounts',
  apiVersion: 'v1',
}

const enum AppName {
  Accounts = 'Accounts',
  Collaborate = 'Collaborate',
  Roster = 'Roster',
}

const config = {
  ...basicConfig,
  signInPath: `${basicConfig.accountApiRootUrl}/web/oauth/google/login?redirect_url=${location.href}`,
  signOutPath: `${basicConfig.accountApiRootUrl}/web/auth/logout`,
  accountApiRootUrl: `${basicConfig.accountApiRootUrl}/${basicConfig.apiVersion}/`,
  collaborateApiRootUrl: `/api/collaborate/${basicConfig.apiVersion}/`,
  collaborateNextApiRootUrl: `/api/collaborate_next/v2/`,
  rosterApiRootUrl: `/api/roster/${basicConfig.apiVersion}/`,
  webPushRootUrl: `/api/wspush/socket.io`,
  dashboardRootUrl: `/api/dashboard`,
  mobileViewWidthBreakPoint: 1200,
  appName: {
    accounts: AppName.Accounts,
    collaborate: AppName.Collaborate,
    roster: AppName.Roster,
  },
  workflowStatuses: Object.keys(WorkflowStatus).map(status => ({
    key: status,
    value: WorkflowStatus[status],
  })),
}

export default config
