import fetchIntercept from 'fetch-intercept'
import uuid from 'uuid/v4'

export class EventSourceHandler {
  public token: string = uuid()

  /**
   * Setup fetch intercept to add token in x-event-source-id header
   *
   * @returns
   * @memberof EventSourceHandler
   */
  public setup() {
    const currentToken = this.token
    fetchIntercept.register({
      request(url: string, options: KeyMap<any>) {
        options.headers['x-event-source-id'] = currentToken
        return [url, options]
      },
    })
  }

  public checkIsSameSource(sourceToken: string) {
    return sourceToken === this.token
  }
}

export default new EventSourceHandler()
